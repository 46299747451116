'use client';

import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  display: flex;
  grid-gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: auto;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: var(--secondary-text);

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 18px;
  }
`;
export const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--primary-text);

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 21px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  width: fit-content;
  padding: 0 25px;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
`;

export const Wrapper = styled.div`
  position: relative;
  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > div {
    padding: 50px 16px;
    display: flex;
    align-items: center;
    grid-gap: 20px;
    border-top: 1px solid var(--border-color);
  }

  @media (max-width: 768px) {
    > div {
      padding: 30px 16px;
      flex-direction: column;

      ${Content} {
        border: none;
        text-align: center;
      }
    }
  }
`;
