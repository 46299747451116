'use client';

import styled from 'styled-components';

export const AppBannerContainer = styled.div`
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 44px;
  }
`;
